import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useOutletContext, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
  useDisclosure,
  Text,
} from '@chakra-ui/react';

import LoadingPage from 'components/app/Loading';
import mapPin from 'assets/images/mapPin.svg';

import { candidateDataInt, swalContent } from 'types';

import Button from 'Library/Button';
import CandidatesTabs from './Tabs';
import {
  getCandidate,
  setloadingCandidate,
  setPrevDrawerJob,
  useCompanyUpdateCandidatesMutation,
  useDeleteCandidatesMutation,
  useFirstNameUpdateCandidatesMutation,
  useGetCandidatesMutation,
  useJobTitleUpdateCandidatesMutation,
  useLastNameUpdateCandidatesMutation,
  useListCandidatesMutation,
  useToggleHideCandidatesMutation,
} from 'store/candidates.slice';
import { getResume } from 'store/candidateresume.slice';
import { AtsConfirm } from 'utils/swal';
import { useListResumeCandidateMutation } from 'store/candidateresume.slice';
import ActionButton from '../components/ActionButton';
import { useGetJobsMutation } from 'store/jobs.slice';

import DynamicInputWidthForm from './components/header/DynamicInputWidthForm';
import CandidatesTagModal from '../Modals/Tag';
import CandidatesAssociateJobModal from '../Modals/AssociateJobs';
// import CandidatesModal from './Modals';
import SubmitToClientModal from 'modules/Jobs/Layout/ViewCandidatesModule/Modal/SubmitToClient';
import CandidatesInterviewModal from './Interviews/modal';
import CandidatesModal from '../Modals/modal';
import {
  useListCandidateFilterMutation,
  usePinListCandidateFilterMutation,
} from 'store/candidatefilter.slice';
import { getJob } from 'store/jobs.slice';
import delay from 'utils/delay';
import SuccessAlert from './components/SuccessAlert';
import Pagination from './components/Pagination';
import { CandidateDetailDrawerOutlet } from './type';
import RenderIf from 'components/RenderIf';
import useGetCandidate from './hooks/useGetCandidate';
import { apiSlice } from 'store/api.slice';

interface CandidateProps {
  isOpen: boolean;
  onClose: () => void;
  rowId: number;
  setRowId: any;
  totalCandidates: number; //TODO: has to remove this
  onPrev: () => void;
  onNext: () => void;
  isLoading?: boolean;
  candidates: any;
}

function CandidatesDrawer({
  isOpen,
  onClose,
  rowId,
  setRowId,
  onPrev,
  onNext,
  isLoading,
  candidates,
}: Partial<CandidateProps>) {
  const outletContext = useOutletContext<CandidateDetailDrawerOutlet>();
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const toast = useToast();
  const urlQuery = new URLSearchParams(location.search);

  const abortFilterControllerRef = useRef<AbortController | null>(null);
  const { candidateData, reloadByJob, prevDrawerJob } = useSelector(
    (state: any) => state.candidates
  );
  const [candidateInfo] = useState<candidateDataInt | null>(candidateData);
  const { jobData } = useSelector((state: any) => state.jobs);
  let emails = candidateInfo?.emails?.filter((email: any) => email.primary);
  emails = emails?.length === 0 ? candidateInfo?.emails : emails;

  const candidateID = Number(params.candidatesId || params?.candidateId);
  const [assocJobs, setAssocJobs] = useState([]);
  const [contactOptionValue, setContactOptionValue] = useState([]);
  const [isMoved, setIsMoved] = useState(false);

  const abortControllerRef = useRef<AbortController | null>(null);

  const jobId = parseInt(params.jobId);
  const hideActionButtons = !location.pathname.includes('/search') && jobId;
  const isGroupByJob = !!urlQuery.get('groupRowId');

  const abortJobControllerRef = useRef<AbortController | null>(null);
  // const abortAttachmentControllerRef = useRef<AbortController | null>(null);
  // const spanRef = useRef<HTMLSpanElement | null>(null);
  // const [inputWidth, setInputWidth] = useState<number | 'auto'>(
  //   spanRef.current?.offsetWidth
  // );
  // const newInputWidth = spanRef.current?.offsetWidth;

  //1. candidate from here
  //2. job details
  const {
    candidateDetails,
    isLoadingCandidate,
    isSuccess: isCandidateSuccess,
  } = useGetCandidate();
  const [reqCandidatesList] = useListCandidatesMutation({
    fixedCacheKey: 'candidate-list',
  });

  // const [reqAttachment] = useListResumeCandidateMutation();
  const [reqGetJob, resGetJob] = useGetJobsMutation();
  const [reqDeleteCandidate, resDeleteCandidate] =
    useDeleteCandidatesMutation();
  const [reqFilterList] = useListCandidateFilterMutation();
  const [reqPinList] = usePinListCandidateFilterMutation();
  const [reqToggleHide, resToggleHide] = useToggleHideCandidatesMutation();
  const [reqFirstNameUpdate] = useFirstNameUpdateCandidatesMutation();
  const [reqLastNameUpdate] = useLastNameUpdateCandidatesMutation();
  const [reqJobTitleUpdate] = useJobTitleUpdateCandidatesMutation();
  const [reqCompanyUpdate] = useCompanyUpdateCandidatesMutation();

  const {
    isOpen: isOpenCandidate,
    onOpen: onOpenCandiate,
    onClose: onCloseCandidate,
  } = useDisclosure();

  const {
    isOpen: isOpenTag,
    onOpen: onOpenTag,
    onClose: onCloseTag,
  } = useDisclosure();

  const {
    isOpen: isOpenAssociate,
    onOpen: onOpenAssociate,
    onClose: onCloseAssociate,
  } = useDisclosure();

  const {
    isOpen: isOpenEmail,
    onOpen: onOpenEmail,
    onClose: onCloseEmail,
  } = useDisclosure();

  const {
    isOpen: isOpenSubmitToClient,
    onOpen: onOpenSubmitToClient,
    onClose: onCloseSubmitToClient,
  } = useDisclosure();

  const {
    isOpen: isOpenScheduleInterview,
    onOpen: onOpenScheduleInterview,
    onClose: onCloseScheduleInterview,
  } = useDisclosure();

  const handleOpenDrawer = () => {
    if (candidateID) {
      return true;
    }
    return isOpen;
  };

  const associateToJob = async () => {
    let list: any = [];
    if (candidateInfo.jobs?.length > 0) {
      await Promise.all(
        candidateInfo?.jobs?.map((job: any) => {
          list.push(job.job_id);
        })
      );
    }

    setAssocJobs(list);
    onOpenAssociate();
  };

  const addTagClick = () => {
    onOpenTag();
  };

  const editCandidate = () => {
    onOpenCandiate();
  };

  const loadPintList = async () => {
    if (abortFilterControllerRef.current) {
      abortFilterControllerRef.current.abort();
    }

    abortFilterControllerRef.current = new AbortController();
    await reqPinList({
      signal: abortFilterControllerRef.current.signal,
    });
  };

  const handleDeleteCandidate = async () => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will delete the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqDeleteCandidate({ id: candidateID });
      await reqFilterList({});

      dispatch(getCandidate({ candidateData: {} }));
      dispatch(
        setloadingCandidate({
          reloadByJob: { ...reloadByJob, reload: true },
        })
      );
    }
  };

  const handleHideCandidate = async () => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will hide the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqToggleHide({ id: candidateID });
      dispatch(getCandidate({ candidateData: {} }));
      dispatch(
        setloadingCandidate({
          reloadByJob: { ...reloadByJob, reload: true },
        })
      );
    }
  };

  const callBackAction = async () => {
    // await fetchCandidate();
  };
  const handleCloseDrawer = () => {
    dispatch(
      apiSlice.util.invalidateTags([
        { type: 'Candidates-Pinned-Filter', id: 'List' },
        { type: 'Candidates-Group-List', id: 'List' },
        { type: 'Candidates-List', id: 'List' },
      ])
    );
    dispatch(getCandidate({ candidateData: {}, candidateDataJobId: null }));
    dispatch(getResume({ latest: {} }));
    dispatch(getResume({ resumes: [] }));
    dispatch(setPrevDrawerJob(null));

    outletContext ? outletContext?.onClose?.() : onClose?.();
  };
  const handleNext = async () => {
    if (isGroupByJob && prevDrawerJob?.job_id !== jobId) {
      //saving current job before moving to next & if job detail is already saved don't dispatch same thing for different candidate
      dispatch(setPrevDrawerJob(candidateDetails?.currentDisplayJob));
    }
    outletContext ? outletContext?.onNext?.() : onNext?.();
  };
  const handlePrev = async () => {
    if (isGroupByJob && prevDrawerJob?.job_id !== jobId) {
      //saving current job before moving to prev  & if job detail is already saved don't dispatch same thing for different candidate
      dispatch(setPrevDrawerJob(candidateDetails?.currentDisplayJob));
    }
    outletContext ? outletContext?.onPrev?.() : onPrev?.();
  };

  // useEffect(() => {
  //   setIsGroupByJob(
  //     candidateOthers &&
  //       candidateOthers.length > 0 &&
  //       candidateOthers?.[0].group_by_job
  //   );
  // }, [candidateOthers]);
  useEffect(() => {
    const load = async () => {
      // await delay(200);
      if (abortJobControllerRef.current) {
        abortJobControllerRef.current.abort();
      }

      abortJobControllerRef.current = new AbortController();
      reqGetJob({
        id: jobId,
        // signal: abortJobControllerRef.current.signal,
      }).then((res: any) => {
        // console.log({ res: res?.data?.data?.data });
        dispatch(getJob({ jobData: res?.data?.data?.data }));
      });
    };
    if (jobId) {
      load();
    } else {
      dispatch(getJob({ jobData: null }));
    }
    dispatch(
      setloadingCandidate({
        reloadByJob: { reload: false, job_id: Number(jobId) },
      })
    );
  }, [jobId]);

  useEffect(() => {
    if (
      params?.jobId !== undefined &&
      params?.jobId !== 'undefined' &&
      params?.jobId !== null &&
      resGetJob.isSuccess
    ) {
      const contactOption = [
        {
          label: [jobData?.lead?.first_name, jobData?.lead?.last_name].join(
            ' '
          ),
          value: jobData?.lead?.primary_email,
        },
      ];

      setContactOptionValue(contactOption);
    }
  }, [resGetJob.isSuccess]);

  // useEffect(() => {
  //   const api = async () => {
  //     // await delay(200);
  //     if (abortAttachmentControllerRef.current) {
  //       abortAttachmentControllerRef.current.abort();
  //     }

  //     abortAttachmentControllerRef.current = new AbortController();
  //     await reqAttachment({
  //       id: candidateID,
  //       signal: abortAttachmentControllerRef.current.signal,
  //     });
  //   };
  //   api();
  // }, [candidateID]);

  // useEffect(() => {
  //   const api = async () => {
  //     await delay(200);
  //     await fetchCandidate();
  //   };

  //   if (resAttachment.isSuccess) {
  //     api();
  //   }
  // }, [resAttachment.isSuccess]);

  useEffect(() => {
    if (!candidateDetails && isCandidateSuccess) {
      if (
        (candidateDetails === null || candidateDetails === undefined) &&
        !isOpenEmail
      ) {
        const title = 'Candidate Details';
        const description = 'Candidate Not Found';
        toast({
          title: title,
          description: description,
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      }
    }
  }, [candidateDetails, isCandidateSuccess]);

  useEffect(() => {
    if (!isOpenScheduleInterview) {
      setContactOptionValue([]);
    }
  }, [isOpenScheduleInterview]);

  useEffect(() => {
    if (resDeleteCandidate.isSuccess || resToggleHide.isSuccess) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();
      // reqCandidatesList({
      //   data: candidatePgBtn,
      //   signal: abortControllerRef.current.signal,
      // });
      handleCloseDrawer();
    }
  }, [resDeleteCandidate.isSuccess, resToggleHide.isSuccess]);

  // useEffect(() => {
  //   if (inputWidth !== newInputWidth) {
  //     setInputWidth(newInputWidth);
  //   }
  // }, [inputWidth, newInputWidth]);

  useEffect(() => {
    if (jobId) {
      dispatch(getCandidate({ candidateDataJobId: jobId }));
    }
  }, [jobId]);
  useEffect(() => {
    if (isGroupByJob && prevDrawerJob?.job_id) {
      setIsMoved(
        prevDrawerJob?.job_id !== candidateDetails?.currentDisplayJob?.job_id
      );
    }
  }, [jobId]);

  return (
    <>
      {candidateInfo && (
        <Drawer
          onClose={handleCloseDrawer}
          isOpen={handleOpenDrawer()}
          size="xl"
        >
          <DrawerOverlay top="60px" />
          <DrawerContent
            maxW="95vw"
            top="62px! important"
            fontFamily="NunitoSans Regular"
            position="relative"
            onClick={() => setIsMoved(false)}
          >
            <DrawerCloseButton
              position="absolute"
              top="0px"
              left="-40px"
              borderRadius="unset"
              p="20px"
              bg="#E6E9EE"
              color="default.secondarytext"
              _hover={{
                backgroundColor: 'none',
              }}
            />
            {isMoved && !isLoadingCandidate ? (
              <SuccessAlert
                content={
                  <Box>
                    Done{' '}
                    <Box as="span">
                      <Link
                        href={`/jobs/${prevDrawerJob?.job_id}/details`}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ fontSize: '20px' }}
                      >
                        {prevDrawerJob?.job?.title}
                      </Link>{' '}
                      -{' '}
                      <Link
                        href={`/clients/all-clients/${prevDrawerJob?.job?.client_id}/overview`}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ fontSize: '20px' }}
                      >
                        {prevDrawerJob?.job?.client?.name}
                      </Link>
                    </Box>
                    . Moving to{' '}
                    <Box as="span">
                      <Link
                        href={`/jobs/${candidateDetails?.currentDisplayJob?.job_id}/details`}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ fontSize: '20px' }}
                      >
                        {candidateDetails?.currentDisplayJob?.job?.title}
                      </Link>{' '}
                      -{' '}
                      <Link
                        href={`/clients/all-clients/${candidateDetails?.currentDisplayJob?.job?.client_id}/overview`}
                        target="_blank"
                        rel="noreferrer"
                        sx={{ fontSize: '20px' }}
                      >
                        {candidateDetails?.currentDisplayJob?.job?.client?.name}
                      </Link>
                    </Box>
                  </Box>
                }
              />
            ) : null}
            <Pagination
              isBlur={isMoved}
              jobDetail={
                candidateDetails?.currentDisplayJob
                  ? {
                      jobLink: `/jobs/${candidateDetails?.currentDisplayJob?.job_id}/details`,
                      jobTitle: candidateDetails?.currentDisplayJob?.job?.title,
                      clientLink: `/clients/all-clients/${candidateDetails?.currentDisplayJob?.job?.client_id}/overview`,
                      clientTitle:
                        candidateDetails?.currentDisplayJob?.job?.client?.name,
                    }
                  : null
              }
              pagination={{
                present: parseInt(urlQuery?.get('rowId')) + 1,
                total: parseInt(urlQuery?.get('total')),
                onPrevious: handlePrev,
                onNext: handleNext,
              }}
            />

            <DrawerBody
              p="0"
              overflow="hidden"
              sx={{ filter: isMoved && 'blur(2px)' }}
            >
              {isLoadingCandidate ? <LoadingPage /> : null}
              <Box
                bg="default.white.800"
                height="auto"
                pt="19px"
                px="20px"
                pb="30px"
              >
                <Flex justifyContent="space-between" mb="20px">
                  <Flex
                    key={candidateInfo.id}
                    gap="6px"
                    flexDir="column"
                    fontSize="18px"
                    lineHeight="21.92px"
                    fontWeight="700"
                    color="default.primarytext"
                  >
                    <Flex mb="2px" gap="5px">
                      <DynamicInputWidthForm
                        sx={{
                          fontSize: '22px',
                          fontWeight: 700,
                          textTransform: 'capitalize',
                        }}
                        value={candidateDetails?.first_name ?? ''}
                        placeholder="First Name"
                        onSubmit={(data: string) => {
                          reqFirstNameUpdate({
                            data: { first_name: data },
                            id: candidateID,
                          });
                        }}
                      />
                      <DynamicInputWidthForm
                        sx={{
                          fontSize: '22px',
                          fontWeight: 700,
                          textTransform: 'capitalize',
                        }}
                        value={candidateDetails?.last_name ?? ''}
                        placeholder="Last Name"
                        onSubmit={(data: string) => {
                          reqLastNameUpdate({
                            data: { last_name: data },
                            id: candidateID,
                          });
                        }}
                      />
                      {candidateDetails?.interviewStatus ? (
                        <Flex
                          borderRadius="2px"
                          bg="#2B2D421A"
                          px="2"
                          fontWeight="normal"
                          fontSize="14px"
                          color="#2B2D42"
                          h="fit-content"
                          // p="1"
                        >
                          {candidateDetails?.interviewStatus}
                        </Flex>
                      ) : null}
                    </Flex>
                    <Flex
                      sx={{
                        gap: '8px',
                        alignItems: 'center',
                        fontSize: '14px',
                        lineHeight: '17.05px',
                      }}
                    >
                      <DynamicInputWidthForm
                        value={candidateDetails?.latest_job_title ?? ''}
                        sx={{
                          fontSize: '16px',
                          fontWeight: 400,
                          textTransform: 'capitalize',
                        }}
                        placeholder="Job Title"
                        onSubmit={(data: string) => {
                          reqJobTitleUpdate({
                            data: { job_title: data },
                            id: candidateID,
                          });
                        }}
                      />
                      <Box fontSize="18px" color="default.gray.600">
                        |
                      </Box>
                      <DynamicInputWidthForm
                        value={candidateDetails?.latest_employer ?? ''}
                        sx={{
                          fontSize: '16px',
                          fontWeight: 400,
                          textTransform: 'capitalize',
                        }}
                        placeholder="Company"
                        onSubmit={(data: string) => {
                          reqCompanyUpdate({
                            data: { company: data },
                            id: candidateID,
                          });
                        }}
                      />
                      {candidateDetails?.currentDisplayJob?.location && (
                        <Flex color="default.gray.1000" gap="5px">
                          <Box fontSize="18px" color="default.gray.600">
                            |
                          </Box>
                          <img
                            src={mapPin}
                            alt="Closemark Circle"
                            style={{
                              width: '14px',
                              height: '14px',
                              objectFit: 'contain',
                            }}
                          />
                          <Text isTruncated data-tooltip-content="show">
                            {/* Round up distance */}

                            <RenderIf
                              condition={
                                !!candidateDetails?.currentDisplayJob
                                  ?.distance_from_job
                              }
                            >
                              {`${candidateDetails?.currentDisplayJob?.distance_from_job} miles from `}
                            </RenderIf>

                            <RenderIf
                              condition={
                                !!candidateDetails?.currentDisplayJob?.location
                              }
                            >
                              {candidateDetails?.currentDisplayJob?.location}
                            </RenderIf>
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                  <Flex alignItems="center" gap={'15px'}>
                    {/* <Flex gap={15}> */}
                    {hideActionButtons ? (
                      <ActionButton
                        handleNextData={handleNext}
                        onClose={handleCloseDrawer}
                        candidateId={candidateID}
                        jobId={jobId}
                      />
                    ) : null}
                    {/* </Flex> */}
                    {/* <Box cursor="pointer">
                      <Link
                        href={`tel:${primaryContact?.number}`}
                        color="rgba(132, 87, 229, 0.8);"
                        sx={{
                          '.fa-sm': {
                            fontSize: '18px',
                          },
                        }}
                      >
                        <FAIcon iconName="phone" />
                      </Link>
                    </Box>
                    <Box
                      color="rgba(132, 87, 229, 0.8);"
                      cursor="pointer"
                      onClick={openSendEmailClick}
                      sx={{
                        '.fa-sm': {
                          fontSize: '18px',
                        },
                      }}
                    >
                      <FAIcon iconName="envelope" />
                    </Box>
                    <Box
                      cursor="pointer"
                      color="rgba(132, 87, 229, 0.8);"
                      sx={{
                        '.fa-sm': {
                          fontSize: '18px',
                        },
                      }}
                    >
                      <FAIcon iconName="comment" />
                    </Box> */}
                    <Menu closeOnSelect={false} isLazy>
                      <MenuButton>
                        <Box
                          sx={{
                            button: {
                              background: 'none',
                              radius: '6px',
                              fontSize: '18px',
                              letterSpacing: '18px',
                              fontWeight: 600,
                              lineHeight: '16px',
                              color: 'primary.600',
                            },
                          }}
                        >
                          {/* <Button rightIcon="chevron-down">Actions</Button> */}
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="ellipsis-h"
                            iconSize="xl"
                          />
                        </Box>
                      </MenuButton>
                      <MenuList fontSize="sm">
                        <MenuItem onClick={() => associateToJob()}>
                          Associate to Job
                        </MenuItem>
                        <MenuItem onClick={() => addTagClick()}>
                          Add Tags
                        </MenuItem>
                        <MenuItem onClick={() => editCandidate()}>
                          Edit
                        </MenuItem>
                        <MenuItem onClick={() => handleHideCandidate()}>
                          Hide
                        </MenuItem>
                        <MenuItem onClick={() => handleDeleteCandidate()}>
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Flex>
                </Flex>
              </Box>
              <Box
                mt="-39px"
                flex="1"
                display="flex"
                flexDirection="column"
                height="100%"
              >
                <CandidatesTabs candidateData={candidateInfo} />
              </Box>
              {isOpenTag && (
                <CandidatesTagModal
                  isOpen={isOpenTag}
                  onClose={onCloseTag}
                  id={candidateID}
                />
              )}
              {isOpenAssociate && (
                <CandidatesAssociateJobModal
                  isOpen={isOpenAssociate}
                  onClose={onCloseAssociate}
                  id={candidateID}
                  assoc_jobs={assocJobs}
                  onSuccess={() => {}}
                />
              )}
              {isOpenCandidate && (
                <CandidatesModal
                  isOpen={isOpenCandidate}
                  onClose={onCloseCandidate}
                  edit={true}
                  id={Number(candidateID)}
                  fromDrawer={true}
                />
              )}
              {isOpenSubmitToClient && (
                <SubmitToClientModal
                  isOpen={isOpenSubmitToClient}
                  onClose={onCloseSubmitToClient}
                  candidate={candidateInfo}
                  callback={callBackAction}
                />
              )}
              {isOpenScheduleInterview && (
                <CandidatesInterviewModal
                  isOpen={isOpenScheduleInterview}
                  onClose={onCloseScheduleInterview}
                  callback={callBackAction} //remove this
                  contactOptionValue={contactOptionValue}
                  isEdit={false}
                />
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
}

export default CandidatesDrawer;
