// Chakra UI hook for modal management
import { useDisclosure } from '@chakra-ui/react';

// Application-specific hooks and utilities
import { useTRservices } from 'components/app/Candidates/Table/useTRservices';
import delay from 'utils/delay';

// React hooks
import { useState, useEffect, useRef } from 'react';

// Redux hooks for state management
import { useSelector, useDispatch } from 'react-redux';

// React Router hook for route parameters
import { useParams } from 'react-router';

// Redux Toolkit queries and mutations for various slices
import { usePinListCandidateFilterMutation } from 'store/candidatefilter.slice';

import {
  useChangeSubStatusMutation,
  useBulkChangeSubStatusMutation,
  useGetCandidatesMutation,
  useListCandidatesMutation,
} from 'store/candidates.slice';

import {
  useListClientAgreementMutation,
  setClientAgreement,
} from 'store/clientagreement.slice';

import { useGetSubStatusMutation } from 'store/constant.slice';
import { useGetJobsMutation } from 'store/jobs.slice';

interface useChangeStatusServicesProps {
  sub_status: number;
  main_status: number;
  isBulkChangeStatus: boolean;
  idList: number[];
  candidate: any;
  id: number;
  onSuccess: (e: any) => void;
  onClose: () => void;
  onOpenAlert: () => void;
}

export const useChangeStatusServices = ({
  sub_status,
  main_status,
  isBulkChangeStatus,
  idList,
  candidate,
  id,
  onSuccess,
  onClose,
  onOpenAlert,
}: useChangeStatusServicesProps) => {
  // Router params
  const params = useParams();

  // Redux selectors
  const { candidate_status } = useSelector((state: any) => state.constants);
  const { clientAgreementPgBtn } = useSelector(
    (state: any) => state.clientAgreements
  );

  const { reloadGroups } = useTRservices();

  const { jobData } = useSelector((state: any) => state.jobs);
  const { candidateData, candidatePgBtn } = useSelector(
    (state: any) => state.candidates
  );

  // Dispatch hook
  const dispatch = useDispatch();

  // API mutations
  const [reqGetJob] = useGetJobsMutation();
  const [reSub] = useGetSubStatusMutation();
  const [reqStatusChange, resStatusChange] = useChangeSubStatusMutation();
  const [reqBulkStatusChange, resBulkStatusChange] =
    useBulkChangeSubStatusMutation();
  const [reqAgreement] = useListClientAgreementMutation();
  const [reqGetCandidate] = useGetCandidatesMutation();
  const [reqpin] = usePinListCandidateFilterMutation();
  const [reqCandidates] = useListCandidatesMutation();

  // Local state
  const [currentStatus, setCurrentStatus] = useState<any[]>([]);
  const [statOption, setStatOption] = useState<any[]>([]);
  const [minHeight, setMinHeight] = useState<string>('10vh');
  const [subId, setSubId] = useState<number | undefined>();
  const [submitData, setSubmitData] = useState<any>({});

  // Disclosure for modal
  const {
    isOpen: isOpenHired,
    onOpen: onOpenHired,
    onClose: onCloseHired,
  } = useDisclosure();

  // Ref for aborting fetch requests
  const abortFilterControllerRef = useRef<AbortController | null>(null);

  // Effect for setting status options
  useEffect(() => {
    if (candidate_status?.length > 0) {
      let groups: any[] = [];
      candidate_status.forEach((item: any) => {
        if (item?.sub?.length > 0) {
          let option: any[] = [];
          item?.sub?.forEach((sub: any) => {
            option.push({
              label: sub.sub_status,
              value: sub.id,
            });
            if (sub_status === sub.id) {
              setCurrentStatus([{ label: sub.sub_status, value: sub.id }]);
            }
          });
          groups.push({
            label: item.status,
            options: option,
          });
        }
      });
      setStatOption(groups);
    }
  }, [candidate_status]);

  // Effect for getting sub status data
  useEffect(() => {
    reSub({ id: main_status });
  }, [main_status]);

  // Submit handler
  const handleSubmit = async (data: any) => {
    const form = {
      sub_id:
        data.sub_id && data.sub_id.length > 0
          ? data.sub_id[0].value
          : data.sub_id.value,
      ...(isBulkChangeStatus && { idList }),
    };
    setSubId(data.sub_id.value);

    if (form.sub_id === 8) {
      setSubmitData(data);

      let clientID = null;
      if (candidate?.jobs?.[0]?.client?.id) {
        clientID = candidate?.jobs?.[0]?.client?.id;
      } else if (
        jobData?.client_id &&
        jobData?.id === candidate?.jobs?.[0]?.id
      ) {
        clientID = jobData?.client_id;
      }
      if (
        candidateData?.id !== candidate?.id ||
        !candidateData?.source?.source
      ) {
        await reqGetCandidate({ id: candidate?.id });
      }
      if (
        jobData?.id !== candidate?.jobs?.[0]?.job_id ||
        !jobData?.lead?.lead_source?.source
      ) {
        await reqGetJob({ id: candidate?.jobs?.[0]?.job_id });
      }
      if (clientID) {
        let newParam = {
          page: 1,
          take: clientAgreementPgBtn.take,
          query: '',
          status: clientAgreementPgBtn.status,
          types: clientAgreementPgBtn.types,
        };
        dispatch(
          setClientAgreement({
            leadAgreementPgBtn: newParam,
          })
        );
        await reqAgreement({ data: newParam, id: clientID });
      }
      onOpenHired();
    } else {
      if (isBulkChangeStatus) {
        reqBulkStatusChange({ data: form })
          .unwrap()
          .then(() => {
            if (params.candidatesTabState) {
              reloadGroups();
              fetchPin();
            } else {
              const load = async () => {
                const candidatedFilterData: string | null =
                  localStorage.getItem('candidatesFilter');
                const filter =
                  candidatedFilterData === null
                    ? candidatePgBtn
                    : JSON.parse(candidatedFilterData);
                await delay(2000);
                reqCandidates({ data: filter });
              };
              load();
            }
            onSuccess({ sub_id: subId, id, candidate });

            onClose();
          });
      } else {
        reqStatusChange({ id, data: form })
          .unwrap()
          .then(() => {
            if (params.candidatesTabState) {
              reloadGroups();
              fetchPin();
            } else {
              const load = async () => {
                const candidatedFilterData: string | null =
                  localStorage.getItem('candidatesFilter');
                const filter =
                  candidatedFilterData === null
                    ? candidatePgBtn
                    : JSON.parse(candidatedFilterData);
                await delay(2000);
                reqCandidates({ data: filter });
              };
              load();
            }
            onSuccess({ sub_id: subId, id, candidate });
            onClose();
          });
      }
    }
  };

  // Callback action for status change
  const callBackAction = () => {
    const form = {
      sub_id:
        (submitData as any)?.sub_id && (submitData as any)?.sub_id.length > 0
          ? (submitData as any)?.sub_id[0].value
          : (submitData as any)?.sub_id.value,
      ...(isBulkChangeStatus && { idList }),
    };
    if (isBulkChangeStatus) {
      reqBulkStatusChange({ data: form });
    } else {
      reqStatusChange({ id, data: form });
    }
  };

  // Function to fetch pinned candidates
  const fetchPin = async () => {
    if (abortFilterControllerRef.current) {
      abortFilterControllerRef.current.abort();
    }
    abortFilterControllerRef.current = new AbortController();
    await reqpin({
      signal: abortFilterControllerRef.current.signal,
    });
  };

  // Effect for handling errors
  useEffect(() => {
    if (resStatusChange.isError || resBulkStatusChange.isError) {
      onOpenAlert();
    }
  }, [resStatusChange.isError, resBulkStatusChange.isError]);

  // Handlers for select focus and blur
  const onFocusSelect = () => {
    setMinHeight('45vh');
  };

  const onBlurSelect = () => {
    setMinHeight('10vh');
  };

  return {
    initialValues: { sub_id: currentStatus },
    handleSubmit,
    minHeight,
    statOption,
    onFocusSelect,
    onBlurSelect,
    isLoading: resStatusChange.isLoading || resBulkStatusChange.isLoading,
    isOpenHired,
    onCloseHired,
    callBackAction,
  };
};
