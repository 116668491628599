import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import AsyncCreatableSelect from 'react-select/async-creatable';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { CheckIcon } from '@chakra-ui/icons';
import Button from 'Library/Button';
import {
  useBulkCreateCandidateTagMutation,
  useCreateCandidateTagMutation,
} from 'store/candidatetag.slice';
import {
  useGetCandidatesMutation,
  useListCandidatesMutation,
} from 'store/candidates.slice';
import { debounce } from 'lodash';
import { useTagListMutation } from 'store/tag.slice';
interface CandidatesModalProps {
  isOpen: any;
  onClose: any;
  id: any;
  idList?: number[];
  isBulkTag?: boolean;
  job_id?: number;
  onSuccess?: () => void;
}

interface optionInterface {
  label: string;
  value: any;
}

const CandidatesTagModal = ({
  isOpen,
  onClose,
  id,
  idList,
  isBulkTag = false,
  job_id,
  onSuccess,
}: CandidatesModalProps) => {
  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const { candidatePgBtn, candidateData } = useSelector(
    (state: any) => state.candidates
  );

  const [reqCandidates, resCandidate] = useListCandidatesMutation();

  const [reqTags, resTags] = useCreateCandidateTagMutation();
  const [reqTagsList, resTagsList] = useTagListMutation();
  const [reqGetCandidate, resGetCandidate] = useGetCandidatesMutation();
  const [reqBulkTags, resBulkTags] = useBulkCreateCandidateTagMutation();
  const [errorMessage, setErrorMessage] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );

  useEffect(() => {
    if (resTags.isSuccess || resBulkTags.isSuccess) {
      try {
        onSuccess?.();
      } catch (e) {
        const params = { ...candidatePgBtn, job_id };
        reqCandidates({ data: params });
      }

      onClose();
    }
    if (resTags.isError || resBulkTags.isError) {
      onOpen();
      const error = resTags.error as any;
      setErrorMessage(error['data']['message']);
    }
  }, [
    resTags.isSuccess,
    resTags.isError,
    resTags.error,
    resBulkTags.isSuccess,
    resBulkTags.isError,
    resBulkTags.error,
    candidatePgBtn,
    onClose,
  ]);

  const [hasTags, setHasTags] = useState('');
  const [tags, setTags] = useState([]);

  const submit = () => {
    if (hasTags !== '') {
      setTags((tags) => [...tags, hasTags]);
      tags.push(hasTags);
    }
    const tagList = tags.map((tag) => tag.label);
    const data = {
      tags: tagList,
      ...(isBulkTag && { idList }),
    };
    if (isBulkTag) {
      reqBulkTags({ data })
        .unwrap()
        .then(() => onSuccess?.());
    } else {
      reqTags({ data, id })
        .unwrap()
        .then(() => onSuccess?.());
    }
  };

  const [candidateTagIds, setCandidateTagIds] = useState([]);

  useEffect(() => {
    if (!isBulkTag) {
      reqGetCandidate({ id });
    }
  }, []);

  useEffect(() => {
    if (!isBulkTag && resGetCandidate.isSuccess) {
      const tags: any = [];
      candidateData?.tags?.map((tag: any) => {
        if (tag.tag_id) tags.push(tag?.tag_id);
      });
      setCandidateTagIds(tags);
    }
  }, [resGetCandidate.isSuccess]);

  const formatTagOption = async (tags: any) => {
    let options: any[] = [];
    await Promise.all(
      tags.map(async (tag: any) => {
        if (!isBulkTag) {
          if (!candidateTagIds.includes(tag.id))
            options.push({
              label: tag.tag_name,
              value: tag.id,
            });
        } else {
          options.push({
            label: tag.tag_name,
            value: tag.id,
          });
        }
      })
    );

    return await options;
  };
  let intialOption: any = [];
  let tagForm = {
    tag: '',
    is_private: false,
  };

  const [tagPrivate, setTagPrivate] = useState('1');

  const promiseTagOptions = debounce(
    (inputValue: string, callback: (options: any[]) => void) => {
      tagForm.tag = inputValue;
      tagForm.is_private = tagPrivate === '1' ? false : true;
      reqTagsList(tagForm).then((res: any) => {
        const getData = async () => {
          const option = await formatTagOption(res?.data?.data);
          callback(option);
        };
        getData().catch(console.error);
      });
    },
    500
  );

  useEffect(() => {
    reqTagsList(tagForm).then((res: any) => {
      const data = res?.data?.data;
      const tagOptions = data.map((item: any) => ({
        value: item.id,
        label: item.tag_name,
      }));
      setDefOption(tagOptions);
    });
  }, []);

  useEffect(() => {
    if (resTagsList.isSuccess) {
      const getData = async () => {
        const option = await formatTagOption(resTagsList.data.data);
        setDefOption(option);
      };
      getData().catch(console.error);
    }
  }, [resTagsList.isSuccess]);

  const [defOption, setDefOption] = useState(intialOption);

  const tagChange = async (value: any) => {
    setTags(value);
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onCloseAlert();
          onClose();
        }}
        size="sm"
        // scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="NunitoSans Regular">
          <ModalHeader
            background="#F4F6F9"
            borderBottom="1px solid gainsboro"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Add Tag
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box>
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>{errorMessage}</AlertDescription>
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody
            borderRadius="0 0 4px 4px"
            p={0}
            fontFamily="NunitoSans Regular"
          >
            <Box
              overflow="automatic"
              p="30px"
              background="#fff"
              borderRadius="8px"
            >
              <FormControl mb="16px">
                <Flex justifyContent="space-between" alignItems="flex-end">
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Add Tags
                  </FormLabel>
                </Flex>
                <AsyncCreatableSelect
                  // onInputChange={(e) => setHasTags(e)}
                  onChange={tagChange}
                  loadOptions={promiseTagOptions}
                  isMulti={true}
                  defaultOptions={defOption}
                  value={tags}
                  styles={{
                    control: (styles: any) => ({
                      ...styles,
                      border: '1px solid #E2E8F0',
                      borderRadius: '6px',
                      cursor: 'pointer',
                      boxShadow: 'none',
                      '&:hover': { border: '1px solid #E2E8F0' },
                      zIndex: 9999,
                    }),
                    menu: (styles: any) => ({
                      ...styles,
                      zIndex: 9999, // Set the zIndex for the dropdown menu
                    }),
                    dropdownIndicator: (styles: any) => ({
                      ...styles,
                      color: '#6930CA',
                      zIndex: 9999,
                    }),
                    placeholder: (styles: any) => ({
                      ...styles,
                      fontSize: '14px',
                      color: '#A0AEC0',
                      zIndex: 9999,
                    }),
                    multiValue: (styles: any) => ({
                      ...styles,
                      background: '#F7FAFC',
                      border: '1px solid #E2E8F0',
                      borderRadius: '6px',
                      '&& div:first-of-type': {
                        color: '#6930CA',
                        fontWeight: 600,
                      },
                      zIndex: 9999,
                    }),
                  }}
                  components={{
                    Option: ({ label, innerProps }: any) => (
                      <Box onClick={(e) => innerProps.onClick(e)} zIndex="9999">
                        <Flex
                          sx={{
                            fontFamily: 'NunitoSans Regular',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: '12px 24px',
                            cursor: 'pointer',
                            position: 'relative',
                          }}
                          _hover={{
                            backgroundColor: 'rgba(239, 229, 255, 0.4)',
                            color: 'primary.800',
                          }}
                        >
                          <Box
                            sx={{
                              fontWeight: 600,
                              fontSize: '12px',
                            }}
                          >
                            {label}
                          </Box>
                        </Flex>
                      </Box>
                    ),
                  }}
                />
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="solid"
              onClick={submit}
              isLoading={resTags.isLoading || resBulkTags.isLoading}
              loadingText="Submitting"
              isDisabled={isOpenAlert}
            >
              Add Tag
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CandidatesTagModal;
