import { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  Button as ChakraButton,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { CheckIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import Button from 'Library/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetCandidateSourceMutation,
  useGetCandidateWorkStatusMutation,
  useGetCountriesMutation,
} from 'store/constant.slice';
import {
  getCandidate,
  useCreateCandidatesMutation,
  useGetCandidatesMutation,
  useListCandidatesMutation,
  useUpdateCandidatesMutation,
} from 'store/candidates.slice';
import { useUserListMutation } from 'store/user.slice';
import FAIcon from 'components/lib/FAIcon';
import { authStorage } from 'utils/localStorage';

import CandidatePersonal from './Personal';
import CandidateProfessional from './Professional';
import CandidateExperience from './Experience';
import CandidateEducation from './Education';
import CandidateTag from './Tag';
import { cleanUpload, uploadList } from 'store/uploads.slice';
import moment from 'moment';
import { changeUploadName } from 'utils/newFilename';
import { useParams } from 'react-router';
import {
  useGetSequenceDataMutation,
  usePostListCandidatesMutation,
} from 'store/outreach.slice';

interface CandidatesModalProps {
  isOpen: any;
  onClose: any;
  edit?: boolean;
  id?: number;
  fromDrawer?: boolean;
  candidateLoading?: boolean;
}

function CandidatesModal({
  isOpen,
  onClose,
  edit = false,
  id = null,
  fromDrawer = false,
  candidateLoading = false,
}: CandidatesModalProps) {
  const toast = useToast();
  const dispatch = useDispatch();
  const authSt = authStorage();
  const urlParam = useParams();

  const { candidatePgBtn, candidateData } = useSelector(
    (state: any) => state.candidates
  );
  const { candidateSequencePgBtn } = useSelector(
    (state: any) => state.outreach
  );
  const { countries, educations, candidate_source } = useSelector(
    (state: any) => state.constants
  );

  const { userList, UserPgBtn } = useSelector((state: any) => state.user);
  const { uploaded, prefix, uploading, resumeUploaded } = useSelector(
    (state: any) => state.uploads
  );

  const [reqGetCandidate, resGetCandidate] = useGetCandidatesMutation();
  const [reqUpdateCandidate, resUpdateCandidate] =
    useUpdateCandidatesMutation();
  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const [reqCreateCandidate, resCreateCandidate] =
    useCreateCandidatesMutation();
  const [reqCountry] = useGetCountriesMutation();
  const [reqCanWorkStat] = useGetCandidateWorkStatusMutation();
  const [reqUserList, resUserList] = useUserListMutation();
  const [reqGetSequenceData] = useGetSequenceDataMutation();
  const [reqSeqCandidates] = usePostListCandidatesMutation();

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const tabListIndex: any = [
    { id: 0, title: 'Personal Information' },
    { id: 1, title: 'Professional Details' },
    { id: 2, title: 'Experience Details' },
    { id: 3, title: 'Education Details' },
    { id: 4, title: 'Tags' },
  ];

  // GLOBAL USE STATES
  const [tabIndex, setTabIndex] = useState(tabListIndex[0]);

  // PERSONAL VALUE USE STATES
  const [countryOption, setCountryOption] = useState([]);
  const [countryDefOption, setCountryDefOption] = useState({});
  const [userSource, setUserSource] = useState([]);

  // EDUCATION VALUE USE STATES
  const [degree, setDegree] = useState([]);

  // useEffect(() => {
  //     if (edit) {
  //         console.log("went to get candidate")
  //         reqGetCandidate({ id });
  //     }
  // }, [edit, id]);

  // useEffect(() => {
  //     if (resGetCandidate.isSuccess) {

  //     }
  // }, [resGetCandidate.isSuccess])

  // INITIAL PERSONAL VALUES AND UTILITIES
  const populateContact = useMemo(() => {
    const contact = candidateData?.contact;
    let data: any = [];
    contact?.map((item: any) => {
      data.push({
        number: item.number,
        type: item.type,
        primary: item.primary,
        category: {
          value: item.category,
          label: (
            <FAIcon
              iconName={item.category === 'personal' ? 'user' : 'briefcase'}
            />
          ),
        },
      });
    });
    if (data.length === 0) {
      data.push({
        number: '',
        type: 'phone',
        primary: true,
        category: {
          value: 'personal',
          label: <FAIcon iconName="user" />,
        },
      });
    }
    return data;
  }, [candidateData]);

  const populateEmails = useMemo(() => {
    const emails = candidateData?.emails;
    let data: any = [];

    emails?.map((item: any) => {
      data.push({
        email: item.email,
        primary: item.primary,
        category: {
          value: item.category || 'personal',
          label: (
            <FAIcon
              iconName={item.category === 'personal' ? 'user' : 'briefcase'}
            />
          ),
        },
      });
    });

    if (data.length === 0) {
      data.push({
        email: '',
        primary: true,
      });
    }
    return data;
  }, [candidateData]);

  const skillPopulate = useMemo(() => {
    const skills = candidateData?.skills;

    let data: any = [];
    skills?.map((item: any) => {
      data.push(item.skill.skill);
    });

    return data;
  }, [candidateData]);

  const sourcePopulate = useMemo(() => {
    const source = candidateData?.source;
    return {
      label: source?.source as string,
      value: source?.id as number,
      sub: source?.sub,
    };
  }, [candidateData]);
  const subSourcePopulate = useMemo(() => {
    let sub_source = null;

    if (candidateData?.subsource) {
      sub_source = {
        label: candidateData?.subsource?.sub_source,
        value: candidateData?.subsource?.id,
      };
    }
    if (candidateData?.sub_source_id) {
      candidate_source.map((source: any) => {
        if (source.id === candidateData?.source?.id) {
          source?.sub?.map((sub: any) => {
            if (sub.id === candidateData?.sub_source_id) {
              sub_source = {
                label: sub?.sub_source,
                value: sub.id,
              };
            }
          });
        }
      });
    }
    if (candidateData?.user_source) {
      sub_source = {
        label: [
          candidateData?.user_source?.first_name,
          candidateData?.user_source?.last_name,
        ]
          .filter(Boolean)
          .join(' '),
        value: candidateData?.user_source?.id,
      };
    }
    if (candidateData?.user_source_id) {
      Object.values(userList).map((item: any) => {
        if (item.id == candidateData?.user_source_id) {
          sub_source = {
            label: [item?.first_name, item?.last_name]
              .filter(Boolean)
              .join(' '),
            value: item?.id,
          };
        }
      });
    }

    if (candidateData?.source?.id === 3 || candidateData?.source?.id === 6) {
      sub_source = {
        value: authSt?.id,
        label: [authSt?.first_name, authSt?.last_name]
          .filter(Boolean)
          .join(' '),
      };
    }
    return sub_source;
  }, [candidateData]);

  useEffect(() => {
    let options: any = [];
    if (countries?.length > 0) {
      countries.map((item: any) => {
        options.push({
          label: item.country,
          value: item.id,
          code: item.code,
        });

        if (item.code === 'us') {
          setCountryDefOption({
            label: item.country,
            value: item.id,
            code: item.code,
          });
        }
      });
    }
    setCountryOption(options);
  }, [countries]);

  useEffect(() => {
    if (isOpen) {
      reqCountry({});
      reqCanWorkStat({});
      reqUserList({ data: UserPgBtn });
    }
  }, [isOpen, candidateData]);
  useEffect(() => {
    if (resUserList.isSuccess) {
      let option: any = [];
      if (Object.values(userList)?.length > 0) {
        Object.values(userList).map((item: any) => {
          option.push({
            value: item.id,
            label: [item?.first_name, item?.last_name].join(' '),
          });
        });
      }
      setUserSource(option);
    }
  }, [resUserList.isSuccess]);

  const countryPopulate = () => {
    let data: any = {};
    if (countries.length > 0) {
      data = countries
        .map((item: any) => ({
          label: item.country,
          value: item.id,
          code: item.code,
        }))
        .filter((val: any) => {
          return val.label === candidateData?.country;
        })[0];
    }
    return data;
  };

  useEffect(() => {
    if (countries.length > 0) {
      setPersonalInitial((prev: any) => {
        return { ...prev, country: countryPopulate() || countryDefOption };
      });
    }
  }, [countries]);

  const initialPersonalValues =
    edit && candidateData && Object.keys(candidateData).length !== 0
      ? {
          first_name: candidateData?.first_name || '',
          last_name: candidateData?.last_name || '',
          dnc: candidateData?.dnc,
          emails: populateEmails || [
            {
              email: '',
              primary: true,
              category: {
                value: 'personal',
                label: <FAIcon iconName="user" />,
              },
            },
          ],
          contact: populateContact || [
            {
              number: '',
              type: 'phone',
              primary: true,
              category: {
                value: 'personal',
                label: <FAIcon iconName="user" />,
              },
            },
          ],
          linkedin_url: candidateData?.linkedin_url || '',
          work_status: candidateData?.work_status_id?.toString(),
          source_type: sourcePopulate || (null as any),
          sub_source_type: subSourcePopulate || (null as any),
          skills: skillPopulate || [],
          zip_code: candidateData?.zip_code,
          city: candidateData?.city,
          state_province: candidateData?.state_province,
          country:
            (countries?.length > 0 &&
              countries
                .map((item: any) => ({
                  label: item.country,
                  value: item.id,
                  code: item.code,
                }))
                .filter(
                  (val: any) => val.label === candidateData?.country
                ))[0] || countryDefOption,
        }
      : {
          first_name: '',
          last_name: '',
          dnc: false,
          emails: [
            {
              email: '',
              primary: true,
              category: {
                value: 'personal',
                label: <FAIcon iconName="user" />,
              },
            },
          ],
          contact: [
            {
              number: '',
              type: 'phone',
              primary: true,
              category: {
                value: 'personal',
                label: <FAIcon iconName="user" />,
              },
            },
          ],
          linkedin_url: '',
          work_status: '',
          source_type: '',
          sub_source_type: null as any,
          skills: [] as any,
          zip_code: '',
          city: '',
          state_province: '',
          country: countryDefOption,
        };

  const [personalInitial, setPersonalInitial] = useState(initialPersonalValues);

  useMemo(() => {
    setPersonalInitial(initialPersonalValues);
  }, [candidateData]);

  // INITIAL PROFESSIONAL VALUES AND UTILITIES
  const initialProfessionalValues =
    edit && candidateData && Object.keys(candidateData).length !== 0
      ? {
          total_year_of_experience:
            candidateData?.total_year_of_experience || null,
          total_months_of_experience:
            candidateData?.total_months_of_experience || null,
          latest_job_title: candidateData?.latest_job_title || '',
          latest_employer: candidateData?.latest_employer || '',
          latest_salary:
            candidateData?.latest_salary?.toLocaleString() ||
            (undefined as any),
          expected_salary_start:
            candidateData?.expected_salary_start?.toLocaleString() ||
            (undefined as any),
          expected_salary_end:
            candidateData?.expected_salary_end?.toLocaleString() ||
            (undefined as any),
          attachments: [] as any,
        }
      : {
          total_year_of_experience: null,
          total_months_of_experience: null,
          latest_job_title: '',
          latest_employer: '',
          latest_salary: undefined as any,
          expected_salary_start: undefined as any,
          expected_salary_end: undefined as any,
          attachments: [] as any,
        };

  const [professionalInitial, setProfessionalInitial] = useState(
    initialProfessionalValues
  );
  // INITIAL EXPERIENCE VALUES AND UTILITIES
  const populateExp = useMemo(() => {
    const experiences = candidateData?.experience;
    let data: any = [];
    experiences?.map((item: any) => {
      data.push({
        company: item.company,
        job_title: item.job_title,
        start_date: moment(item.start_date).format('MM/DD/YYYY HH:MM:SS'),
        end_date: moment(item.end_date).format('MM/DD/YYYY HH:MM:SS'),
        currently_pursuing: item.currently_pursuing,
      });
    });
    return data;
  }, [candidateData]);

  const initialExperienceValues =
    edit && candidateData && Object.keys(candidateData).length !== 0
      ? {
          experiences: populateExp || [],
          resume_link: candidateData?.resume_link || '',
        }
      : {
          experiences: [] as any,
          resume_link: '',
        };

  const [experienceInitial, setExperienceInitial] = useState(
    initialExperienceValues
  );

  // INITIAL EDUCATION VALUES AND UTILITIES

  const populateEducation = useMemo(() => {
    const educations = candidateData?.educations;
    let data: any = [];
    educations?.map((item: any) => {
      data.push({
        school: item.school,
        major_department: item.major_department,
        degree_level_id: item?.degree_level_id || 6,
        start_date: moment(item.start_date).format('MM/DD/YYYY HH:MM:SS'),
        end_date: moment(item.end_date).format('MM/DD/YYYY HH:MM:SS'),
        currently_pursuing: item.currently_pursuing,
      });
    });

    return data;
  }, [candidateData]);

  const populateDegree = () => {
    let options: any = [];
    educations?.map((item: any) => {
      options.push({
        value: item.id,
        label: item.name,
      });
    });
    setDegree(options);
  };

  useEffect(() => {
    if (isOpen) {
      populateDegree();
    }
  }, [isOpen, candidateData]);

  const initialEducationValues =
    edit && candidateData && Object.keys(candidateData).length !== 0
      ? {
          educations: populateEducation || [],
        }
      : {
          educations: [],
        };

  const [educationInitial, setEducationInitial] = useState(
    initialEducationValues
  );

  // INITIAL TAG VALUES AND UTILITIES
  const tagPopulate = useMemo(() => {
    const tags = candidateData?.tags;
    let data: any = [];
    tags?.map((item: any) => {
      data.push(item.tag.tag_name);
    });

    return data;
  }, [candidateData]);
  const initialTagValues =
    edit && candidateData && Object.keys(candidateData).length !== 0
      ? {
          tags: tagPopulate || [],
        }
      : {
          tags: [],
        };

  const [tagInitial, setTagInitial] = useState(initialTagValues);

  const [submitDetails, setSubmitDetails] = useState({} as any);

  const updateInitialValues = (data: any) => {
    switch (tabIndex.id) {
      // PERSONAL VALUES
      case 0:
        setPersonalInitial((prev: any) => ({
          ...prev,
          ...data,
        }));
        break;
      // PROFESSIONAL VALUES
      case 1:
        setProfessionalInitial((prev: any) => ({
          ...prev,
          ...data,
        }));
        break;
      // EXPERIENCE VALUES
      case 2:
        setExperienceInitial((prev: any) => ({
          ...prev,
          ...data,
        }));
        break;
      // EDUCATION VALUES
      case 3:
        setEducationInitial((prev: any) => ({
          ...prev,
          ...data,
        }));
        break;
      // TAG VALUES
      case 4:
        setTagInitial((prev: any) => ({
          ...prev,
          ...data,
        }));
        break;
      default:
        console.log('tab index out of bounds');
        break;
    }
  };

  const onSubmitNext = (data: any) => {
    const newParam = {
      ...submitDetails,
      ...data,
    };

    setSubmitDetails(newParam);
    updateInitialValues(data);
    setTabIndex(tabListIndex[tabIndex.id + 1]);
  };

  const onPrev = (data: any) => {
    updateInitialValues(data);
    setTabIndex(tabListIndex[tabIndex.id - 1]);
  };

  useEffect(() => {
    if (resCreateCandidate.isSuccess && urlParam?.settingTypeId) {
      toast({
        title: 'New candidate added.',
        description: "We've added your new candidate.",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });

      setTimeout(() => {
        reqGetSequenceData({ id: urlParam?.settingTypeId });
        reqSeqCandidates(candidateSequencePgBtn);
      }, 2000);
    }
  }, [resCreateCandidate.isSuccess]);

  useEffect(() => {
    if (resCreateCandidate.isSuccess || resUpdateCandidate.isSuccess) {
      onCloseAlert();
      onClose();
      if (!urlParam?.jobsTabNav) {
        reqCandidates({ data: candidatePgBtn });
      }

      if (edit && fromDrawer) {
        reqGetCandidate({ id });
      } else {
        dispatch(getCandidate({ candidateData: {} }));
      }
      //putting this in here. being outside of this condition making the resumeUploaded empty before submitting
      dispatch(cleanUpload({ resumeUploaded: [] }));
      dispatch(cleanUpload({ resumeAttachments: [] }));

      dispatch(uploadList({ prefix: 'Attachment' }));
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
    } else if (resCreateCandidate.isError || resUpdateCandidate.isError) {
      onOpen();
    }
  }, [
    resCreateCandidate.isError,
    resCreateCandidate.isSuccess,
    resUpdateCandidate.isSuccess,
    resUpdateCandidate.isError,
  ]); //removing unnecessary dependencies here

  const onSubmit = async (data: any) => {
    data['attachments'] = await changeUploadName(resumeUploaded, prefix);
    data['resumes_id'] = resumeUploaded.map((resume: any) => resume?.id);

    let param = {
      ...submitDetails,
      ...data,
    } as any;

    param.latest_salary = Number(
      (param.latest_salary as string)?.replace(/,/g, '')
    );
    param.expected_salary_start = Number(
      (param.expected_salary_start as string)?.replace(/,/g, '')
    );
    param.expected_salary_end = Number(
      (param.expected_salary_end as string)?.replace(/,/g, '')
    );

    param.source_type = param.source_type?.label;
    param.sub_source_type = param.sub_source_type?.value || null;

    const checkEmailPrimary = param.emails.some(
      (item: any) => item.primary === true
    );
    param.emails[0].primary = checkEmailPrimary
      ? param.emails[0]?.primary
      : true;

    if (param.contact.length > 0) {
      const checkContactPrimary = param.contact.some(
        (item: any) => item.primary === true
      );
      param.contact[0].primary = checkContactPrimary
        ? param.contact[0]?.primary
        : true;
      const filteredContact = param.contact.filter(
        (item: any) => item.number !== ''
      );
      param.contact = filteredContact;
    }

    param.contact = param.contact.map((item: any) => {
      return {
        ...item,
        category: item.category.value,
      };
    });

    param.emails = param.emails.map((item: any) => {
      return {
        ...item,
        category: item.category.value,
      };
    });

    param.country = param.country.label;

    if (urlParam.settingTypeId) {
      param = {
        ...param,
        sequence_id: Number(urlParam.settingTypeId),
      };
    }

    if (edit) {
      await reqUpdateCandidate({ data: param, id });
    } else {
      param['data_source'] = 'form';
      await reqCreateCandidate(param);
    }
  };

  const CandidateModalFooter = (
    handleSubmit?: (e: any) => void,
    errors?: any,
    isValid?: boolean,
    values?: any,
    uploading?: boolean
  ) => {
    return (
      <ModalFooter
        position="sticky"
        bottom="0"
        background="#F9F9FB"
        mb="20px"
        mr="20px"
        gap="16px"
      >
        {tabIndex.id > 0 && (
          <ChakraButton
            disabled={isOpenAlert}
            mr={3}
            onClick={() => {
              onPrev(values);
            }}
          >
            Previous
          </ChakraButton>
        )}
        {tabIndex.id < 4 && (
          <Button
            variant="solid"
            // type="submit"
            disabled={isOpenAlert}
            onClick={handleSubmit}
          >
            Next
          </Button>
        )}
        {tabIndex.id === 4 && (
          <Button
            variant="solid"
            // type="submit"
            onClick={() => {
              handleSubmit(values);
              console.log(errors);
              !isValid && onOpen();
            }}
            disabled={isOpenAlert || uploading}
            loading={
              resCreateCandidate.isLoading || resUpdateCandidate.isLoading
            }
          >
            {edit ? 'Update' : 'Save'}
          </Button>
        )}
      </ModalFooter>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onCloseAlert();
        onClose();
        !edit && dispatch(getCandidate({ candidateData: {} }));
        dispatch(cleanUpload({ resumeUploaded: [] }));
        dispatch(cleanUpload({ resumeAttachments: [] }));

        dispatch(uploadList({ prefix: 'Attachment' }));
        dispatch(cleanUpload({ attachments: [] }));
        dispatch(cleanUpload({ uploaded: [] }));
      }}
      size="5xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="NunitoSans Regular">
        <ModalHeader
          background="#F4F6F9"
          borderBottom="1px solid gainsboro"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          {edit ? 'Edit Candidate' : 'Add Candidate'}
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        {isOpenAlert && (
          <Box>
            <Alert status="error" justifyContent="space-around">
              <AlertIcon />
              <Box>
                <AlertTitle>Oh snap!</AlertTitle>
                <AlertDescription>
                  There was an error processing your request. Change a few
                  things up and try again.
                </AlertDescription>
              </Box>
              <CloseButton
                position="relative"
                right={-1}
                top={-3}
                onClick={onCloseAlert}
              />
            </Alert>
          </Box>
        )}
        <ModalBody
          borderRadius="0 0 4px 4px"
          p={0}
          fontFamily="NunitoSans Regular"
        >
          <Tabs colorScheme="purple" variant="unstyled" index={tabIndex.id}>
            <Flex gap={2} background="#F9F9FB">
              <Box
                width="30%"
                py={4}
                overflowY="scroll"
                borderRight="1px solid"
                borderColor="transparent"
                px={6}
              >
                <TabList
                  flexDirection="column"
                  alignItems="flex-start"
                  gap="8px"
                >
                  {tabListIndex.map((tab: any) => {
                    return (
                      <Tab
                        key={tab.id}
                        justifyContent="space-between"
                        width="100%"
                        border="0.5px solid rgba(155, 155, 155, 0.5)"
                        borderRadius="3px"
                        background="#fff"
                        p="12px 20px"
                        lineHeight="18px"
                        fontSize="14px"
                        fontWeight="600"
                        _selected={{
                          bg: '#EFE5FF',
                          border: '0.5px solid #EFE5FF',
                        }}
                      >
                        {tab.title}
                        <Icon
                          as={ChevronLeftIcon}
                          transition="all .25s ease-in-out"
                          transform={isOpen ? 'rotate(180deg)' : ''}
                          w={6}
                          h={6}
                          ml="8px"
                          color={
                            tabIndex.id === tab.id ? 'primary.800' : '#fff'
                          }
                        />
                      </Tab>
                    );
                  })}
                </TabList>
              </Box>
              <Box width="70%">
                <TabPanels>
                  {tabIndex.id === 0 && (
                    <CandidatePersonal
                      initialValues={personalInitial}
                      onSubmit={onSubmitNext}
                      CandidateModalFooter={CandidateModalFooter}
                      edit={edit}
                      candidateData={candidateData}
                      loading={candidateLoading || resUserList.isLoading}
                      countryOption={countryOption}
                      isOpenAlert={isOpenAlert}
                      userSource={userSource}
                    />
                  )}
                  {tabIndex.id === 1 && (
                    <CandidateProfessional
                      initialValues={professionalInitial}
                      onSubmit={onSubmitNext}
                      CandidateModalFooter={CandidateModalFooter}
                    />
                  )}
                  {tabIndex.id === 2 && (
                    <CandidateExperience
                      initialValues={experienceInitial}
                      onSubmit={onSubmitNext}
                      CandidateModalFooter={CandidateModalFooter}
                    />
                  )}
                  {tabIndex.id === 3 && (
                    <CandidateEducation
                      initialValues={educationInitial}
                      onSubmit={onSubmitNext}
                      CandidateModalFooter={CandidateModalFooter}
                      degree={degree}
                    />
                  )}
                  {tabIndex.id === 4 && (
                    <CandidateTag
                      initialValues={tagInitial}
                      onSubmit={onSubmit}
                      CandidateModalFooter={CandidateModalFooter}
                    />
                  )}
                </TabPanels>
              </Box>
            </Flex>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CandidatesModal;
