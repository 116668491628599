// Chakra UI components
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';

import { useState } from 'react';

// Formik for form handling
import { Field, ErrorMessage, FieldProps, useFormikContext } from 'formik';

// Custom components and utilities
import AtsSelectContact from 'components/app/AtsSelectContact';
import AtsSelectAddress from 'components/app/AtsSelectAddress';
import {
  useFetchMyClientListQuery,
  useFetchClientLocationsQuery,
  useFetchClientContactsQuery,
  useLazyFetchClientContactsQuery,
} from 'services/client/client.query';
import { debounce } from 'lodash';
import Select from 'components/ui/Select/Select';
import {
  useFetchDefaultPitchesQuery,
  useFetchJobSpecificationsQuery,
} from 'services/common/constants.query';
import { getClient } from '../../utils/selectors';
import { IntialValueType } from '../../utils/validationSchema';
import { useDispatch } from 'react-redux';
import { setClientId } from 'store/client.slice';
import { useFetchUsersQuery } from 'services/user/users.query';
import { setActionOptionsFor } from 'sweetalert/typings/modules/state';

type FieldFormProps = FieldProps<IntialValueType>;
interface ClientDetailFormProps {
  isHideQuickPost?: boolean;
  isClientDisabled?: boolean;
  isContactDisabled?: boolean;
}
export default function ClientDetailForm({
  isHideQuickPost,
  isClientDisabled,
  isContactDisabled,
}: ClientDetailFormProps) {
  const dispatch = useDispatch();
  const [query, setQuery] = useState({ client: '', cc: '', bcc: '' });
  const { values } = useFormikContext<IntialValueType>();

  const { data: userList } = useFetchUsersQuery(
    {},
    {
      selectFromResult: ({ data, ...other }) => {
        if (data) {
          return {
            data: data
              ? data?.map((user) => ({
                  label: `${user.first_name} ${user.last_name}`,
                  value: user.email,
                }))
              : [],
            ...other,
          };
        }
        return { data: [], ...other };
      },
    }
  );
  const { data: defaultPitches } = useFetchDefaultPitchesQuery(
    {},
    {
      selectFromResult: ({ data, ...other }) => ({
        data: data
          ? data.reduce((acc: Record<number, typeof curr>, curr) => {
              acc[curr.id] = curr;
              return acc;
            }, {})
          : {},
        ...other,
      }),
    }
  );
  const { data: clientList, isFetching: isClientLoading } =
    useFetchMyClientListQuery(
      {
        data: {
          filters: ['is_active'],
          page: 1,
          take: 50,
          query: query.client,
        },
      },
      {
        selectFromResult: ({ data, ...other }) => {
          if (data) {
            return {
              data: getClient(data.data),
              ...other,
            };
          }
          return { data: [], ...other };
        },
      }
    );
  const { data: industryList, isFetching: isIndustryLoading } =
    useFetchJobSpecificationsQuery(
      {},
      {
        selectFromResult: ({ data, ...other }) => {
          if (data) {
            return {
              data: data.map((data) => ({
                label: data.specification,
                value: data.id,
              })),
              ...other,
            };
          }
          return { data: [], ...other };
        },
      }
    );

  const { data: locationList, isFetching: isLocationLoading } =
    useFetchClientLocationsQuery(
      { id: values?.client?.value },
      {
        skip: !Boolean(values?.client?.value),
        selectFromResult: ({ data, ...other }) => ({
          data: data
            ? data.map((location) => ({
                value: location?.id,
                label: `${location?.city ?? ''}, ${location?.state ?? ''}, ${
                  location?.zip_code ?? ''
                }, ${location?.country?.country ?? ''}`,
                data: location,
                ...location,
              }))
            : [],
          ...other,
        }),
      }
    );
  const {
    contactList,
    emailList,
    isFetching: isLoadingContactList,
  } = useFetchClientContactsQuery(
    {
      data: {
        filters: [],
        page: 1,
        query: '',
        take: 50,
        client_id: values?.client?.value,
      },
    },
    {
      selectFromResult: ({ data, ...other }) => ({
        ...other,
        contactList: data
          ? data.data.map((contact) => ({
              label: `${contact.first_name} ${contact.last_name}`,
              value: contact.id,
              title: contact.title,
            }))
          : [],
        emailList: data
          ? data.data.map((contact) => ({
              label: `${contact.first_name} ${contact.last_name}`,
              value: contact.primary_email,
            }))
          : [],
      }),
      skip: !Boolean(values?.client?.value),
    }
  );
  const handleQueryUpdate = debounce(
    (value: string, name: 'client' | 'cc' | 'bcc') => {
      setQuery((state) => ({ ...state, [name]: value }));
    },
    400
  );

  return (
    <Field>
      {({
        form: {
          values,
          handleChange,
          errors,
          touched,
          setFieldValue,
          handleBlur,
        },
      }: FieldFormProps) => {
        return (
          <Stack justifyContent="space-between" h="100%">
            <Box
              h="100%"
              sx={{
                p: '16px',
                background: '#fff',
                borderRadius: '8px',
              }}
            >
              <>
                {/* Job Post Type Radio Group */}
                <FormControl
                  isInvalid={Boolean(!!errors.job_type && touched.job_type)}
                >
                  <RadioGroup value={String(values.job_type)} mb="24px">
                    <Stack spacing={5} direction="row">
                      <Radio
                        name="job_type"
                        value="1"
                        onChange={(e: any) => {
                          handleChange(e);
                          if (e.target.checked) {
                            setFieldValue(
                              'body',
                              defaultPitches?.[1]?.content ?? ''
                            );
                            setFieldValue(
                              'subject',
                              defaultPitches?.[1]?.subject ?? ''
                            );
                          }
                        }}
                      >
                        Full Job Post
                      </Radio>

                      {/* Conditional rendering based on modal edit mode */}
                      {!isHideQuickPost ? (
                        <Radio
                          name="job_type"
                          value="2"
                          onChange={(e: any) => {
                            handleChange(e);
                            if (e.target.checked) {
                              setFieldValue(
                                'body',
                                defaultPitches?.[2]?.content ?? ''
                              );
                              setFieldValue(
                                'subject',
                                defaultPitches?.[2]?.subject ?? ''
                              );
                            }
                          }}
                        >
                          Quick Job Posts
                        </Radio>
                      ) : null}
                    </Stack>
                  </RadioGroup>
                  <ErrorMessage name="job_type" component={FormErrorMessage} />
                </FormControl>

                {/* Company and Contact Fields */}
                <Flex gap="16px" mb="24px">
                  <FormControl
                    isInvalid={Boolean(!!errors.client && touched.client)}
                    isRequired
                  >
                    <FormLabel>Client</FormLabel>
                    <Field
                      name="client"
                      options={clientList}
                      component={Select}
                      value={values.client}
                      variant="outline"
                      isError={!!errors.client && !!touched.client}
                      isLoading={isClientLoading}
                      isDisabled={isClientDisabled}
                      onChange={(e: any) => {
                        //For Address edit/create is used
                        //TODO: need to remove such implementation
                        dispatch(setClientId({ client_id: e.value }));
                        setFieldValue('client', {
                          label: e.label,
                          value: e.value,
                        });
                        setFieldValue('contact', '');
                        setFieldValue('managerList', e.manager);
                        setFieldValue('location', '');
                        setFieldValue('industry', {
                          value: e.industry.id,
                          label: e.industry.specification,
                        });
                        setFieldValue('account_manager', e?.manager?.[0] ?? '');
                        setFieldValue(
                          'company_website',
                          e?.company_website ?? ''
                        );
                        setFieldValue(
                          'primary_recruiter',
                          e?.manager?.[0] ?? ''
                        );
                      }}
                      placeholder="Select Client"
                      defaultInputValue={query.client}
                      onInputChange={(value: string) => {
                        handleQueryUpdate(value, 'client');
                      }}
                    />

                    <ErrorMessage
                      name="client"
                      render={(msg: any) => (
                        <FormErrorMessage>{msg?.label}</FormErrorMessage>
                      )}
                    />
                  </FormControl>

                  <FormControl
                    isInvalid={Boolean(!!errors.contact && touched.contact)}
                    isRequired
                  >
                    <FormLabel>Contact</FormLabel>

                    <Field
                      name="contact"
                      isLoading={isLoadingContactList}
                      options={contactList}
                      component={AtsSelectContact}
                      value={values.contact}
                      client={values.client}
                      placeholder="Select Contact"
                      isDisabled={isContactDisabled}
                      isError={Boolean(!!errors.contact && touched.contact)}
                      onCallback={(data: any, contact: any) => {
                        setFieldValue('contact', {
                          label: `${contact?.first_name} ${contact?.last_name}`,
                          value: contact.id,
                        });
                      }}
                    />

                    <ErrorMessage
                      name="contact"
                      render={(msg: any) => (
                        <FormErrorMessage>{msg?.label}</FormErrorMessage>
                      )}
                    />
                  </FormControl>
                </Flex>

                {/* Location Field */}
                <FormControl
                  isInvalid={Boolean(!!errors.location && touched.location)}
                  mb="24px"
                  isRequired
                >
                  <FormLabel>Location</FormLabel>
                  <Field
                    name="location"
                    options={locationList}
                    component={AtsSelectAddress}
                    value={values.location}
                    isLoading={isLocationLoading}
                    placeholder="Select Location"
                    isError={Boolean(errors.location && touched.location)}
                    onCreate={(e: any) => {
                      setFieldValue('location', {
                        label: e?.label ?? '',
                        value: e?.value ?? '',
                      });
                      setFieldValue('city', e?.city ?? '');
                      setFieldValue('state', e?.state ?? '');
                      setFieldValue('zip_code', e?.zip_code ?? '');
                      setFieldValue(
                        'country',
                        e?.country?.country
                          ? {
                              label: e?.country?.country,
                              value: e?.country?.code,
                            }
                          : ''
                      );
                    }}
                    onChange={(e: any) => {
                      setFieldValue('location', {
                        label: e.label,
                        value: e.value,
                      });
                      setFieldValue('city', e?.city ?? '');
                      setFieldValue('state', e?.state ?? '');
                      setFieldValue('zip_code', e?.zip_code ?? '');
                      setFieldValue(
                        'country',
                        e?.country?.country
                          ? {
                              label: e?.country?.country,
                              value: e?.country?.code,
                            }
                          : ''
                      );
                    }}
                  />
                  <ErrorMessage
                    name="location"
                    render={(msg: any) => (
                      <FormErrorMessage>{msg?.label}</FormErrorMessage>
                    )}
                  />
                </FormControl>

                <FormControl
                  isInvalid={Boolean(!!errors.industry && touched.industry)}
                  mb="24px"
                  isRequired
                >
                  <FormLabel>Industry</FormLabel>
                  <Field
                    name="industry"
                    options={industryList}
                    component={Select}
                    value={values.industry}
                    onChange={(e: any) => setFieldValue('industry', e)}
                    placeholder="Select Industry"
                    isError={!!errors.industry && !!touched.industry}
                    isLoading={isIndustryLoading}
                  />

                  <ErrorMessage
                    name="industry"
                    render={(msg: any) => (
                      <FormErrorMessage>{msg?.label}</FormErrorMessage>
                    )}
                  />
                </FormControl>

                {/* CC and BCC Options */}
                <Flex gap="16px" mb="60px">
                  <FormControl isInvalid={Boolean(!!errors.cc && touched.cc)}>
                    <FormLabel>CC</FormLabel>

                    <Field
                      name="cc"
                      isLoading={isLoadingContactList}
                      component={AtsSelectContact}
                      value={values.cc}
                      client={values.client}
                      options={[].concat(emailList).concat(userList)}
                      isMulti={true}
                      placeholder="Select CC"
                      isSaveInput={true}
                      isError={Boolean(!!errors.cc && touched.cc)}
                      onCallback={(data: any, contact: any) => {
                        setFieldValue('cc', [
                          ...values?.cc,
                          {
                            label: `${contact?.first_name} ${contact?.last_name}`,
                            value: contact.primary_email,
                          },
                        ]);
                      }}
                    />
                  </FormControl>
                  <FormControl isInvalid={Boolean(!!errors.bcc && touched.bcc)}>
                    <FormLabel>BCC</FormLabel>

                    <Field
                      name="bcc"
                      isLoading={isLoadingContactList}
                      component={AtsSelectContact}
                      value={values.bcc}
                      client={values.client}
                      onBlur={() => handleBlur(values)}
                      options={[].concat(emailList).concat(userList)}
                      isMulti={true}
                      placeholder="Select BCC"
                      defaultValue={values.bcc}
                      isSaveInput={true}
                      isError={Boolean(!!errors.bcc && touched.bcc)}
                      onCallback={(data: any, contact: any) => {
                        setFieldValue('bcc', [
                          ...values?.bcc,
                          {
                            label: `${contact?.first_name} ${contact?.last_name}`,
                            value: contact.primary_email,
                          },
                        ]);
                      }}
                    />
                  </FormControl>
                </Flex>
              </>
            </Box>
          </Stack>
        );
      }}
    </Field>
  );
}
